<template>
  <div>
    <AdminTitle title="My Projects">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="getProjects(); getFolders();"
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-btn
            v-if="$store.getters['user/role'] == 'admin' || $store.getters['user/data'].organization || myProjectsCount < ($store.getters['user/role'] == 'client'? $store.getters['generalSettings/maxClientProjects'] : $store.getters['generalSettings/maxProjects'])"
            @click="$parent.showAdd()"
            color="primary"
            small
          >
            Create New Project
          </v-btn>

          <v-btn
            @click="$parent.showFolderDialog()"
            color="primary"
            outlined
            small
          >
            Create Folder
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <!-- Filters -->
    <div>
      <div class="d-flex justify-space-between align-center mb-10">
        <div>
          <div v-if="status.displayRange">
            Displaying <span class="font-weight-bold">{{ orderedFolders.length }} {{ pluralize('folder', orderedFolders.length) }}</span> and <span class="font-weight-bold">{{ orderedProjects.length }} {{ pluralize('project', orderedProjects.length) }}</span> in the last {{ status.displayRange }} days
          </div>
          <div v-else>
            Displaying all <span class="font-weight-bold">{{ orderedFolders.length }} {{ pluralize('folder', orderedFolders.length) }}</span> and <span class="font-weight-bold">{{ orderedProjects.length }} {{ pluralize('project', orderedProjects.length) }}</span>
          </div>

          <div v-if="$store.getters['user/role'] == 'user'" class="caption grey--text" type="info" border="left" text>
            {{ myProjectsCount }} of {{ $store.getters['user/data'].organization ? 'unlimited' : $store.getters['generalSettings/maxProjects'] }} projects created. <span v-if="projects.length == 3">You've reached the maximum number of projects allowed.</span>
          </div>
        </div>

        <div class="d-flex gap-12 align-center">

          <v-sheet max-width="250">
            <v-select
              @change="
                getProjects()
                getFolders()
              "
              v-model="status.displayRange"
              :items="displayRanges"
              item-text="name"
              item-value="value"
              prefix="Display: "
              hide-details
              class="dashboard-select"
              outlined
              dense
            ></v-select>
          </v-sheet>

          <v-sheet max-width="250">
            <v-select
              v-model="order"
              :items="orderBy"
              item-text="name"
              item-value="value"
              prefix="Sort by: "
              hide-details
              class="dashboard-select"
              outlined
              dense
            ></v-select>
          </v-sheet>

          <v-btn-toggle mandatory>
            <v-btn @click="changeView('grid')" small icon>
              <v-icon small>mdi-view-grid</v-icon>
            </v-btn>
            <v-btn @click="changeView('list')" small icon>
              <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>

      <div v-show="listView == 'grid'">
        
        <preloader v-if="status.getting" />
        
        <v-alert type="info" border="left" v-if="!status.getting && !projects.length" text>No project found</v-alert>
        
        <v-fade-transition
          v-if="!status.getting && listView == 'grid'"
          name="projects"
          class="row"
          tag="div"
          group
        >
          <template v-if="folders.length">
            <v-col cols="3" v-for="folder in orderedFolders" :key="folder.id">
              <v-card
                :to="{ name: 'Folder', params: { id: folder.id } }"
                class="fill-height"
                outlined
                hover
              >
                <v-card-text class="d-flex fill-height justify-center align-center">
                  <div>
                    <v-badge
                      :color="countFolderContent(folder) ? 'dark' : 'grey'"
                      :content="countFolderContent(folder) ? countFolderContent(folder) : `0`"
                      :offset-x="25"
                      :offset-y="45"
                      bordered
                      overlap
                      large
                    >
                      <v-icon
                        :color="countFolderContent(folder) ? 'primary' : 'grey'"
                        size="120"
                      >mdi-folder-outline</v-icon>
                    </v-badge>
                    <h5 class="text-center body-2 font-weight-bold">{{ folder.name }}</h5>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
          <template v-if="projects.length">
            <v-col cols="3" v-for="project in orderedProjects" :key="project.id">
              <div 
              v-if="project.collaborator"
              class="d-flex justify-end mr-4">
              <v-tooltip top>
              <template v-slot:activator="{ on }">
              <v-avatar 
              tile
              v-on="on"
              class="collaborator-badge rounded-t-xl"
              color="success"
              size="35px">
                <v-icon
                color="white"
                >
                  mdi-account-group-outline
                </v-icon>
              </v-avatar>
              </template>
                <span class="caption"><span class="text-capitalize">Project Collaborator</span></span>
                </v-tooltip>
              </div>
              

              <project-block
                :project="project"
                :disableMoveProjectFolder="!$store.getters['projects/isOwner'](project)"
                @delete="confirmDelete(project)"
                @update="$parent.showAdd()"
                @move="$parent.moveFolder"
                @upload="showUploadDialog(project)"
                deletable
              />
            </v-col>
          </template>
        </v-fade-transition>
      </div>

      <preloader v-if="status.getting && listView == 'list'" />
      <div v-if="!status.getting">
        <h5 v-if="listView == 'list'" class="mb-3">Folders</h5>
        <v-alert v-if="!orderedFolders.length && listView == 'list'" type="info" border="left" class="mb-0" text>No Folder found.</v-alert>
        <v-row v-if="!status.getting && orderedFolders.length && listView == 'list'">
          <!-- =================  ==List Folders== ================= -->
          <v-col>
            <v-card class="flex-grow-1" outlined>
              <template>
                <v-alert v-if="!projects.length" type="info" border="left" class="mb-0" text>No Folder found.</v-alert>
                <v-simple-table v-if="orderedFolders.length && listView == 'list'">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th class="text-center">Projects</th>
                        <th class="text-center">Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="folder in orderedFolders"
                        :key="folder.id"
                        outlined
                        hover
                      >
                        <td>
                          <div class="d-flex align-center">
                            <div>
                              <span class="font-weight-bold">
                                <router-link 
                                  :to="{ name: 'Project', params: { id: folder.id } }"
                                  :class="!countFolderContent(folder) ? 'text-decoration-none grey--text' : 'text-decoration-none'"
                                >
                                  <v-icon class="mr-2"
                                    :color="countFolderContent(folder) ? 'primary' : 'grey'"
                                    size="25"
                                  >mdi-folder-outline</v-icon>
                                  {{ folder.name }}
                                </router-link>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">{{ countFolderContent(folder) ? countFolderContent(folder) : `0` }}</td>
                        <td class="text-caption text-center grey--text">
                          <span class="caption">{{ folder.createdAt | fromNow }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </template>
            </v-card>
          </v-col>
          <!-- =================  ==End List Folders== ================= -->
        </v-row>
      </div>

      <v-divider class="my-7" v-if="listView == 'list'"></v-divider>

      <preloader v-if="status.getting && listView == 'list'" />
      <div v-if="!status.getting">
        <h5 v-if="listView == 'list'" class="mb-3">Projects</h5>
        <v-alert v-if="!orderedProjects.length && listView == 'list'" type="info" border="left" class="mb-0" text>No Project found.</v-alert>
        <v-row v-if="!status.getting && projects.length && listView == 'list'">
          <!-- =================  ==List Folders== ================= -->
          <v-col>
            <v-card class="flex-grow-1">
              <template>
                <v-alert v-if="!projects.length" type="info" border="left" class="mb-0">No users found.</v-alert>
                <v-simple-table v-if="orderedProjects.length && listView == 'list'">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th width="15"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="project in orderedProjects"
                        :key="project.id"
                        outlined
                        hover
                      >
                        <td>
                          <div class="d-flex align-center">
                            <span class="font-weight-bold">
                              <router-link :to="{ name: 'Project', params: { id: project.id } }" class="text-decoration-none">
                                <div 
                                  v-if="project.collaborator"
                                >
                                  <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                    v-on="on"
                                    color="primary"
                                    class="mr-2"
                                    >
                                      mdi-account-group-outline
                                    </v-icon>
                                    {{ project.name }}
                                  </template>
                                    <span class="caption"><span class="text-capitalize">Project Collaborator</span></span>
                                    </v-tooltip>
                                  </div>
                                  <div v-if="!project.collaborator">
                                    {{ project.name }} <span class="ml-2 font-weight-medium"> created {{ project.createdAt | fromNow }} ago</span>
                                  </div>
                              </router-link>
                            </span>
                          </div>
                        </td>
                        <td>
                          <v-menu bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="primary"
                                class="subtle"
                                icon
                                v-on="on"
                                small
                              >
                                <v-icon>mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item @click="updateThisProject(project)" dense class="subtle">
                                <v-list-item-title class="primary--text"><v-icon small left>mdi-form-textbox</v-icon> Rename</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="moveThisFolder(project)" dense class="subtle">
                                <v-list-item-title class="primary--text"><v-icon small left>mdi-folder-upload-outline</v-icon> Move To Folder</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item class="subtle" dense>
                                <v-list-item-title><v-icon small left class="red--text">mdi-delete-outline</v-icon> <span class="red--text">Delete Project</span> </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </template>
            </v-card>
          </v-col>
          <!-- =================  ==End List Folders== ================= -->
        </v-row>
      </div>

    </div>
    <!-- End Filters -->

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="showDeleteDialog"
      :deleting="status.deleting"
      :message="deleteDialogMessage"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
      textConfirmation="Project"
      captcha
    />

    <!-- UPLOAD LOGO -->
    <UploadLogoDialog 
      ref="uploadLogoDialog" 
      :project="toUpload" 
    />
  </div>
</template>

<script>

import ProjectBlock from '@/components/ProjectBlock'
import UploadLogoDialog from '@/views/project/UploadLogoDialog'
import { mapState, mapActions } from 'vuex'
import _orderBy from 'lodash/orderBy'
import pluralize from 'pluralize'

export default {
  name: 'Projects',

  metaInfo: {
    title: 'My Projects'
  },

  components: {
    ProjectBlock,
    UploadLogoDialog,
  },

  data() {
    return {
      pluralize,
      toUpload: null,
      confirm: false,
      toDelete: null,
      listView: 'grid',
      order: 'createdAt',
      deleteMessage: null,
      showDeleteDialog: false,
      deleteDialogMessage: '',
      displayRanges: [
        {
          name: 'Last 60 Days',
          value: 60
        },
        {
          name: 'Last 30 Days',
          value: 30
        },
        {
          name: 'Last 7 Days',
          value: 7
        },
        {
          name: 'All',
          value: 0
        },
      ],

      orderBy: [
        {
          name: 'Date Created',
          value: 'createdAt'
        },
        {
          name: 'Name',
          value: 'name'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.projects.status,
      folders: state => state.folders.folders,
      projects: state => state.projects.projects,
      projectStatus: state => state.project.status,
    }),


    orderedFolders: function () {
      let orderTypeFolders = this.order == 'createdAt' ? 'desc' : 'asc'

      return _orderBy(this.folders.filter(
          folder => {
            return folder.id !== 'root'
          }
        ), this.order , orderTypeFolders)
    },

    orderedProjects: function () {
      let orderTypeProjects = this.order == 'createdAt' ? 'desc' : 'asc'

      return _orderBy(this.projects.filter(
        project => {
          return project.folder == 'root'
        }
      ), this.order , orderTypeProjects)
    },
    
    myProjectsCount: function () {
      let projects = this.projects.filter(project => this.$store.getters['projects/isOwner'](project))
      return projects.length
    }
  },

  methods: {
    ...mapActions('projects', [
      'getProjects',
      'deleteProject'
    ]),

    ...mapActions('folders', [
      'getFolders'
    ]),

    updateThisProject(project) {
      this.$store.commit('projects/setData', project)
      this.$parent.showAdd()
    },

    moveThisFolder(project) {
      this.$parent.moveFolder(project)
    },

    countFolderContent(folder) {
      let projects = this.projects.filter(project => {
        return project.folder == folder.id
      })

      return projects.length
    },

    changeView( active ) {
      this.listView = active
    },

    closeDeleteDialog() {
      this.showDeleteDialog = false,
      this.toDelete = null
    },

    confirmDelete(project) {
      this.toDelete = project
      this.showDeleteDialog = true
      this.deleteDialogMessage = `Delete project ${project.name}?`
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteProject(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    showUploadDialog(project) {
      this.toUpload = project
      this.$refs.uploadLogoDialog.dialog = true
    }
  },

  created() {
    if (!this.status.firstLoad) this.getProjects()
    if (!this.$store.state.folders.status.firstLoad) this.getFolders()
    this.$store.commit('allProjects/clearHits')
  }
}

</script>
