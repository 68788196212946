<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
  >
    <close-btn @click="dialog = false" depressed overflow />

    <v-card>
      <v-card-text class="pa-5">
        <v-img
          v-if="tempUrl"
          :src="tempUrl"
          class="mb-5"
          min-height="300"
          aspect-ratio
          contain
        ></v-img>

        <v-progress-linear 
          v-show="progress"
          :value="progress"
          class="my-5"
        ></v-progress-linear>

        <v-file-input
          v-if="!uploading"
          v-model="file"
          @change="readLogoData"
          accept="image/png, image/jpeg, image/svg+xml"
          hide-details
          outlined
        >
          <template #append>
            <v-btn 
              @click="uploadLogo()"
              :disabled="!file"
              color="accent gradient" 
              class="mt-n2"
            >Upload</v-btn>
          </template>
        </v-file-input>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase'

export default {
  props: ['project'],

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
      tempUrl: null,
      progress: 0,
      file: null,
      uploading: false,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    readLogoData(e) {
      if (e) this.tempUrl = URL.createObjectURL(e)
      else this.tempUrl = null
    },

    async uploadLogo() {
      var storageRef = firebase.storage().ref()

      if (this.project.logo) storageRef.child(`logos/${this.project.logo}`).delete()

      this.uploading = true
      let name = `${Date.now()}_${this.project.id}_${this.file.name}`

      var metadata = {
        contentType: this.file.type
      }

      var uploadTask  = storageRef.child(`logos/${name}`).put(this.file, metadata)

      await uploadTask.on('state_changed', snapshot => {
        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }, error => {
        this.$store.dispatch('showError', error.message, { root: true })
        console.log(error.message)
      }, () => {
        this.project.ref.update({ logo: name })
        .then(() => {
          this.dialog = false
          this.progress = 0
          this.uploading = false
          this.$store.dispatch('showSuccess', 'Logo uploaded')
          this.file = null
          this.tempUrl = null

          this.$store.commit('projects/updateProjectLogo', {
            name,
            id: this.project.id
          })
        })
        .catch(error => {
          this.dialog = false
          this.progress = 0
          this.uploading = false
          this.$store.dispatch('showError', error.message)
          console.log(error.message)
        })
      })
    },
  }
}
</script>